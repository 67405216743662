import React, {useState, useEffect} from "react"
import { useStaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Layout from "../components/layout"
import Image from "../components/image"
import {LazyExpand} from "../components/post/lazy"

import "../styles/parts/ranks.sass"
import star from "../icons/star.svg"
import workers from "../data/images/ranks/workers.png"

const Sem = () => {
    const data = useStaticQuery(graphql`
    query {
      refer: allReferJson {
        edges {
          node {
            vpn
            url
            logo{
              childImageSharp{
                fluid(maxHeight: 140) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            sem{
                intro
                features
                comment
                user
            }
          }
        }
      }
      score: allDataJson{
        edges {
          node {
            foreignRiview {
              vpns {
                slug
                average {
                  score
                }
                score{
                    speed
                    stable
                    price
                }
              }
            }
          }
        }
      }
    }
  `)
//    const source=""
    const [source, setSource] = useState("")
    useEffect(()=>{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const keyword = urlParams.get('source');
        setSource(keyword);
    },[])
    const content = data.refer.edges
    const score = data.score.edges.filter((edge)=> {return edge.node.foreignRiview !== null})[0].node.foreignRiview
    let vpns = []
    score.vpns.forEach((vpn)=>{
        const slug = vpn.slug
        const vpnRefer = content.filter((refer)=> {return refer.node.vpn === slug;})[0].node
        if (vpnRefer.sem !== null && vpn.average.score){
            vpns.push({
                "slug": slug,
                "url": vpnRefer.url,
                "logo": vpnRefer.logo,
                "intro": vpnRefer.sem.intro,
                "features": vpnRefer.sem.features,
                "comment": vpnRefer.sem.comment,
                "user": vpnRefer.sem.user,
                "score": vpn.average.score,
                "speed": vpn.score.speed,
                "stable": vpn.score.stable,
                "price": vpn.score.price
            })
        }
    })

    return(
        <Layout title="2021最好用的翻墙VPN与梯子推荐(含VPN下载) - 翻墙101">
            <div id="sem">
                <div className="title" >
                    <div className="container" >
                        <div className="row">
                        <div className="col-7">
                            <h1>中国最好用的VPN推荐</h1>
                            <p><span className="check">&#10004; </span>六大城市实测，只推荐<strong>【100%能翻墙】</strong>的软件</p>
                            <p><span className="check">&#10004; </span>2021年最新优惠，包含<strong>【免费试用】</strong>软件</p>
                            <p><span className="check">&#10004; </span>解锁Youtube（油管）， Netflix（奈飞）以及PS5，Switch外区</p>
                            <p><span className="check">&#10004; </span>无障碍浏览Instagram，Facebook，Twitter以及Clubhouse</p>
                            <p><span className="check">&#10004; </span>专业IP检测，确保用户<strong>匿名浏览</strong></p>
                        </div>
                        <div className="col-5">
                            <img id="workers" src={workers} />
                        </div>
                        </div>
                    </div>
                </div>
                <div className="content container">
                    <h6>最后更新时间：2021年2月</h6>
                    <div className="rank">
                        <table className="table table-bordered">
                            <thead className="table-dark">
                                <tr>
                                <th scope="col" style={{width: "20%"}}>名次</th>
                                <th scope="col" style={{width: "45%"}}>特色</th>
                                <th scope="col" style={{width: "20%"}}>评分</th>
                                <th scope="col" style={{width: "15%"}}>官网地址</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vpns.map((vpn,idx)=>(
                                    <tr>
                                    <th scope="row">
                                        {idx === 0 && <p className="rank-num">中国用户首选</p>}
                                        {idx !== 0 && <p className="rank-num">{idx+1}</p>}
                                        <div className="vpn-card-logo">
                                            <Image src={vpn.logo.childImageSharp} alt={vpn.slug+" logo"} className="vpn-card-logo-img"  />
                                            <div className="rating">
                                                <ul className="line" >
                                                    <li><img src={star} alt="star" /></li>
                                                    <li><img src={star} alt="star" /></li>
                                                    <li><img src={star} alt="star" /></li>
                                                    <li><img src={star} alt="star" /></li>
                                                    {vpn.score >= 9 && <li><img src={star} alt="star" /></li>}
                                                </ul>
                                                {vpn.score >= 9 && <p className="line" >五星好评</p>}
                                                {vpn.score < 9 && <p className="line" >四星好评</p>}
                                            </div>
                                        </div>
                                    </th>
                                    <td>
                                        <p className="intro">{vpn.intro}</p>
                                        {vpn.features.map((feature)=><p className="features">
                                            <span className="check">&#10004; </span><span>{feature}</span>
                                        </p>)}
                                    </td>
                                    <td className="score">
                                        <p className="label" > &#128640; 速度表现：</p>
                                        <div class="progress">
                                            {console.log(vpn.speed)}
                                            <div class="progress-bar" role="progressbar" style={{width: vpn.speed*10+"%" }} aria-valuenow={vpn.speed*10} aria-valuemin="0" aria-valuemax="100">{vpn.speed*10+"%"}</div>
                                        </div>
                                        <p className="label" > &#128202; 翻墙稳定度：</p>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style={{width: vpn.stable*10+"%" }} aria-valuenow={vpn.stable*10} aria-valuemin="0" aria-valuemax="100">{vpn.stable*10+"%"}</div>
                                        </div>
                                        <p className="label" > &#128176; 性价比：</p>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style={{width: vpn.price*10+"%" }} aria-valuenow={vpn.price*10} aria-valuemin="0" aria-valuemax="100">{vpn.price*10+"%"}</div>
                                        </div>
                                    </td>
                                    <td className="link">
                                        <span className="score-p">{vpn.score}</span>
                                        <OutboundLink class="btn vpn-link" role="button" target="_blank" rel="noreferrer nofollow"
                                            href={vpn.url.replace("/vpn/","/key/")+"/?keyword=sem-"+source}
                                            >前往官网
                                        </OutboundLink>
                                    </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div id="review">
                        <h2>我们的评测标准</h2>
                        <p>“中国地区”独有的防火长城GFW，使得许多国外能用的VPN在中国无法使用。许多VPN推荐网站使用美国地区的评测结果进行推荐，造成不少用户购买之后无法使用的尴尬情况。</p>
                        <p>为了能客观的提供有价值的VPN推荐，翻墙101不计成本于中国六大城市向阿里巴巴、腾讯等公司租用服务器，坚持所有评测均在中国进行。同时针对每个VPN软件进行了IP安全性审查，只有在确定符合安全标准、并且能顺利翻墙的情况下才会进行推荐。</p>
                    </div>
                    <div id="feedback">
                        <h2>用户评价</h2>
                        <div id="comments">
                            {vpns.map((vpn)=>(
                                <div class="card bg-light mb-3" style={{minWidth: "540px"}} >
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div className="left-card">
                                            <Image src={vpn.logo.childImageSharp} alt={vpn.slug+" logo"} className="vpn-card-logo-img"  />
                                            <div className="rating">
                                                <ul className="line" >
                                                    <li><img src={star} alt="star" /></li>
                                                    <li><img src={star} alt="star" /></li>
                                                    <li><img src={star} alt="star" /></li>
                                                    <li><img src={star} alt="star" /></li>
                                                    {vpn.score >= 9 && <li><img src={star} alt="star" /></li>}
                                                </ul>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="card-body">
                                                <h5 class="card-title">{vpn.user}</h5>
                                                <p class="card-text">{vpn.comment}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div id="faq">
                        <h2>常见问题</h2>
                        <LazyExpand title="如果我对速度不满意，能否退款？" >
                            <p>各位网友请放心选择，我们所推荐的VPN皆支持无理由不满意退款，如果有任何不满意的地方，联系客服即可。</p>
                        </LazyExpand>
                        <LazyExpand title="你们的测评可靠么？" >
                            <p>我们通过科学的方法进行测评，不同于诸多博主个人的胡扯甚至拿自家的电脑进行测试。</p>
                            <p>我们通过部署在中国主要城市的服务器, 消耗了大量的计算资源，通过在不同环境下真实模拟用户的体验，得出最为客观公正的结果。</p>
                        </LazyExpand>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Sem